.navbarclass {
  background-color:   rgba(8, 0, 99, 1);
  /* background-color: rgba(255, 111, 0, 1); */
  color: white;
}

.offcanvasClass{
  background-color: #ff6f00 !important;
  /* background-color:   rgba(8, 0, 99, 1); */
  color: white;
}

.navtext {
    color: white;
}

/* -----------------nav welcome */
.navmain h1 {
  font-family: Comic Sans MS, Brush Script Std, cursive;
  color: white;
  font-size: medium;
  padding: 0px;
  margin: 0px;
}
.navwrapper img{
  border-radius: 25%;
}
.navmain {
  height: max-content;
  width: 200px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: screen;
  position: relative;
  top: 0px;
  left: 0px;
} 
.navwrapper span:first-child {
  transform: translate(-0.255em, -0.25em);
}
.navwrapper span:last-child {
  --color: #b6acff;
  transform: translate(0.255em, 0.25em);
} 