.login {
  /* background-color: rgb(8, 0, 69); */
  background: rgb(8, 0, 99);
  background: linear-gradient(
    90deg,
    rgba(8, 0, 99, 1) 0%,
    rgba(255, 111, 0, 1) 100%
  );
  margin: auto;
  height: inherit;
}

@media screen and (max-width: 992px) {
  .login {
    height: max-content;
    justify-items: center;
  }
}

.login-container {
  margin-right: 0;
  margin: auto;
  height: 100%;
}
.login-logo {
  height: 50%;
}

.login-title {
  font-size: 50px;
  color: white;
  text-shadow: 1px 1px 1px gray;
  margin: auto;
  margin-bottom: 5px;
}

.login-input {
  /* border: 1px solid white; */
  border-radius: 5%;
  max-width: 350px !important;
  margin: auto !important;
  box-shadow: 1px 1px 1px white;
}

.login-input label {
  display: flex;
}

.login label {
  color: black;
}

.label {
  color: white;
}

.connecting:after {
  content: '';
  animation: dots 2s linear infinite;
}

@keyframes dots {
  0%, 20% {
    content: ' .';
  }
  40% {
    content: ' ..';
  }
  60% {
    content: ' ...';
  }
  90%, 100% {
    content: '';
  }
}