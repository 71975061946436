.App {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.table thead {
  text-align: "." start;
  background-color: rgba(8, 0, 99, 1);
  /* background-color:   #4643f1; */
  color: white;
  border-color: rgb(8, 0, 99);
  border-radius: 25%;
  /* background-color:  rgba(255, 111, 0, 1); */

}

.table-bordered {
  border: 1px double rgb(244, 245, 254) !important;
}

.table-hover tbody tr:hover {
  color: rgb(255, 255, 255) !important;
  /* background-color:  rgba(255, 111, 0, 1); */
  background-color: #cde9eb;
}

.btn-alert {
  background-color: #003693 !important;
  /* background-color: cutom-color; */
  border-color: #ffffff !important;
  /* border-color: cutom-cor; */
  /* color: #ff0000 !important; */
  box-shadow: 5px 5px 5px gray;

}

.btn-alert button,
.btn-info button {
  color: #ffffff;
}

.btn-info {
  background-color: rgb(198, 42, 3) !important;
  /* background-color: cutom-color; */
  border-color: #ffffff !important;
  /* border-color: cutom-cor; */
  /* color: #ff0000 !important; */
  box-shadow: 5px 5px 5px gray;

}

@media only screen and (max-width: 1026px) {
  .icons {
    display: none;
  }
  .bascule{
    display: none;
    visibility:hidden;
  }
}