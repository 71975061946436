@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
main {
  height: 50vh;
  overflow: hidden;
  /* background: radial-gradient(farthest-corner, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(https://picsum.photos/1280/800) center/cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  font-size: 20vmin;
  height: 2em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: screen;
}
.wrapper.invert span {
  color: black;
}
.wrapper.invert span::before {
  -webkit-text-stroke: 0.1em var(--color);
}
.wrapper span {
  --color: #cecece;
  font-family: Impact, "Anton", Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: 700;
  font-style: italic;
  display: block;
  position: absolute;
  color: var(--color);
  letter-spacing: -0.005em;
}
.wrapper span::before, .wrapper span::after {
  content: attr(data-text);
  display: block;
  position: relative;
  padding: 0 0.1em;
  z-index: 1;
}
.wrapper span::before {
  position: absolute;
  -webkit-text-stroke: 0.1em black;
  z-index: 0;
}
.wrapper span:first-child {
  transform: translate(-0.255em, -0.25em);
}
.wrapper span:last-child {
  --color: #b6acff;
  transform: translate(0.255em, 0.25em);
}

